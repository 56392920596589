import React from 'react'
import { Button } from 'react-bootstrap';

//   Icons
// import { LuUser2 } from "react-icons/lu";
import { FaFacebook, FaInstagramSquare, FaYoutubeSquare } from "react-icons/fa";
// import { FaArrowRight } from "react-icons/fa";

const Herosection = () => {
    return (
        <div className='hero-section'>
            <div className="container">

                {/* Link Section */}
                <div className='Content-box' style={{ minHeight: "100vh" }}>
                    <h1>రాగం తానం పల్లవి <br />
                        TeamRTP Weekly Musicians Meet</h1>
                    <p className='text-center'>TeamRTP Virtual Musicians Meet @ home theaters connected!!!</p>
                    <div className='Link-section'>
                        <p>Please save/click this link </p>
                        <a href="https://docs.google.com/presentation/d/e/2PACX-1vSMgbtzfVSDP7ioyTyO0913AtPWHGstqcaC6mNMMLt026rIPex4TuGwZcsIPDrKfGTJuqgLArc5hsMQ/pub?start=true&loop=false&delayms=1000&slide=id.gcb9a0b074_1_0" target='_blank'>https://tinyurl.com/RTPAgenda</a>
                    </div>
                </div>



                {/* UPCOMING EVENT Section */}
                <div className='Content-box'>
                    <p className='event-p'>UPCOMING CELEBRITY EVENT</p>
                    <img src="images/Rplayer.webp" alt="" className='w-100' />
                </div>


                {/*Video Section */}
                <div className='Video-Section'>
                    <p className='event-p mb-0'>SAMEERA BHARADWAJ EVENT</p>
                    <p className='mb-4'>VijayadaSAMEERAgam Event Promo</p>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/uxf2Xw7J2k8?si=0sSPSO-_gzKxziq2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                {/*Donate Section */}
                <div className='Donate-Section'>
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="images/R-player-logo.webp" alt="" className='w-100' />
                        </div>
                        <div className="col-lg-6 mb-lg-0 mb-5">
                            <p className='event-p'>Donate for a cause</p>
                            <div className="donate_flex">
                                <div className='d-flex flex-column gap-2'>
                                    <h3>help the needy</h3>
                                    <h5>$9.99</h5>
                                </div>
                                <Button>DONATE</Button>
                                <p className=''>We wish every hand extend support to the noble cause to extend</p>
                                <p>We sincerely thank for your contributions!!</p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Contact Us */}
                <div className='Contact-Section'>
                    <p className='event-p mb-0'>Contact Us</p>
                    <h5>EMAIL US : THETEAMRTP@GMAIL.COM</h5>
                    <Button className='Whatsapp_btn'>Message us on Whatsapp</Button>
                    <h5>Raagam Taanam Pallavi</h5>
                    {/* <p className='contact-p'>+17879004867</p> */}
                    <Button className='Get-btn'>GET IN TOUCH !</Button>
                </div>

                {/* Footer Section */}
                {/* <div className='footer_section'>
                    <p>COPYRIGHT ©2020 TEAMRTP - ALL RIGHTS RESERVED.</p>
                    <div className='icons-box'>
                        <FaFacebook />
                        <FaInstagramSquare />
                        <FaYoutubeSquare />
                    </div>
                    <p>WWW.TEAMRTP.ORG</p>
                </div> */}
            </div>

        </div>
    )
}

export default Herosection
