import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
//   Icons
import { LuUser2 } from "react-icons/lu";
import { FaFacebook, FaInstagramSquare, FaYoutubeSquare } from "react-icons/fa";


import {
    Link,
} from "react-router-dom";

const Sidebar = () => {
    return (
        <div className='Sidebar'>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home"><img src="images/SingerVoice-logo.gif" alt="" className='w-100'/></Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="">
                            <Link to="/">Home</Link>
                            <Link to="/">Account Signin</Link>
                            <Link to="/">Events</Link>
                            {/* <Link to="/">Weekly Live</Link>
                            <Link to="/">About Us</Link>
                            <Link to="/">Contact Us</Link> */}

                        </Nav>
                    </Navbar.Collapse>

                    <div className='Sidebar-footer'>
                        <div style={{borderRight:"5px solid #000;",paddingRight:"5px"}}><LuUser2 /></div>
                        <div><FaFacebook /></div>
                        <div><FaInstagramSquare /></div>
                        <div><FaYoutubeSquare /></div>
                    </div>
                </Container>
            </Navbar>
            {/* <div className='logo'>
                <img src="images/SingerVoice-logo.gif" alt="" />
        </div>

            <div>
            <div className="Routers-flex">
                            <Link to="/">Home</Link>
                            <Link to="/about">About</Link>
                            <Link to="/why">Why Us</Link>
                             <Link to="/packages">Packages</Link>
                            <Link to="/faq's">FAQ'S</Link>
                            <Link to="/contact">Contact Us</Link>
               </div>
               
            </div> */}

        </div>
    )
}

export default Sidebar
