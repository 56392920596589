import './App.scss';
import './Responsive.scss';
import Herosection from './Components/Herosection';
import Topbar from './Components/Sidebar/Navbar';
import Sidebar from './Components/Sidebar/Sidebar';

function App() {
  return (
    <div className="App">
      <div className='d-flex'>
        <Sidebar/>
        <div className='Scroller'>
          <Topbar/>
        <Herosection/>
        </div>
        </div>
    </div>
  );
}

export default App;
