import { React, useState } from "react";
import { Button, Container, Navbar, Dropdown, Offcanvas, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Topbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div className="Topbar d-lg-none">
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container fluid>

                        <Button variant="light" onClick={handleShow} className="Offcanvas-btn">
                            <img src="/images/toggle.png" alt="" />
                        </Button>

                        <Offcanvas show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                {/* <Offcanvas.Title><img src="images/Music-Image.png" alt="Logo" /></Offcanvas.Title> */}
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="offcanvas-flex">
                                    <>
                                        <Link to="/">Home</Link>
                                        <Link to="/">Account Signin</Link>
                                        <Link to="/">Events</Link>
                                    </>

                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>


                        <Navbar.Brand href="" className="Navbar-logo">
                            <Link to="/">

                                <img src="images/Music-Image.png" alt="Logo" />
                            </Link>
                        </Navbar.Brand>


                        <div className="Login-portion ms-0">
                            {/* <Button className="Submit">Submit Video</Button> */}

                            {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <AccountCircleIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {user && <Button>{user?.name}</Button>}
                                {user ?
                                    <Button onClick={handleLogout}>Logout</Button>
                                    :
                                    <Link to="/login">
                                        <Button>Login</Button>
                                    </Link>
                                }
                            </Dropdown.Menu>
                        </Dropdown> */}
                        </div>

                        {/* <Form className="flex-box">
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className=""
                        />
                        <Button className='icon-btn'><SearchIcon /></Button>
                    </Form> */}
                    </Container>
                </Navbar>
            </div>
        </div>
    )
}

export default Topbar
